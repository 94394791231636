import React from "react"
import { Footer } from "../../components/footer/Footer"
import { Header } from "../../components/header/Header"
import BannMarque from "../../components/marque/BanniereMarque"
import MarqueContenu from "../../components/marque/marqueContent"

const MarquePage = ({ location }) => {
  return (
    <div>
      <Header />
      <BannMarque
        id={typeof location.state === `undefined` ? "0" : location.state.id}
      />
      <MarqueContenu
        id={typeof location.state === `undefined` ? "0" : location.state.id}
      />
      <Footer />
    </div>
  )
}

export default MarquePage
